/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var menuOffsetTop = ($('nav.nav-primary').length > 0) ? $('nav.nav-primary').offset().top : 0;

  var Casadivita = {
    // All pages

    'common': {
      init: function () {

        window.bLazy = new Blazy();

        // menu events
        $(window).scroll(function () {
          if ($(window).scrollTop() > menuOffsetTop) {
            if (!$('nav.nav-primary').hasClass('sticky')) {
              $('nav.nav-primary').addClass('sticky');
              $('header[role="banner"]').addClass('margin-bottom');
            }
          } else {
            $('nav.nav-primary').removeClass('sticky');
            $('header[role="banner"]').removeClass('margin-bottom');
          }
        });

        $(window).resize(function(){
          if($('nav.nav-primary').length > 0){
            menuOffsetTop = $('nav.nav-primary').offset().top;
          }
        });

        $('nav.nav-primary .container-menu ul>li>a, #menu-header-mobile-left ul li a').on("mousedown", function () {
          var menuId = $(this).attr('data-menu-id');
          if (menuId !== undefined) {
            if (!$(this).parent().hasClass('active')) {
              $('nav.nav-primary .container-menu li.active').each(function () {
                var activeMenuId = $('a', this).attr('data-menu-id');
                $('nav.nav-primary #' + activeMenuId).animate({'height': 'toggle'}, 300, 'linear');
                $(this).removeClass('active');
              });
              $('#menu-header-mobile-left ul li.active').each(function () {
                var activeMenuId = $('a', this).attr('data-menu-id');
                $('nav.nav-primary #' + activeMenuId).animate({'height': 'toggle'}, 300, 'linear');
                $(this).removeClass('active');
              });
              $(this).parent().addClass('active');
              $('nav.nav-primary #' + menuId).animate({'height': 'toggle'}, 300, 'linear');
              $('nav.nav-primary #' + menuId + ' input[type="text"]').focus();
            } else {
              $(this).parent().removeClass('active');
              $('nav.nav-primary #' + menuId).animate({'height': 'toggle'}, 300, 'linear');
            }
            return false;
          }
        });
        $('#catapult-cookie-bar .ctcc-inner').on('click',function(){
          $('#catapult-cookie-bar').css('padding-bottom','0px');
          $(':after',this).css('content','');
          $(this).toggleClass('full-text');
        });
        $('.top-footer .widget:eq(2)').addClass('last');
        $('.custom-toggle').each(function () {
          var parent = $(this);
          $('.toggle-action', this).on('mouseenter', function () {
            var direction = $('.toggle-menu', parent).data('direction');
            parent.addClass('active');
            switch (direction) {
              case 'top':
                break;
              default:
                break;
            }
          });
          $(this).on('mouseleave', function () {
            parent.removeClass('active');
          });
        });

        //menu piatto unico
        $('.wrap-menu-inner .trigger-menu').on("click",function(){
          $('.menu-menu-piatto-unico-container', $(this).parent()).stop(false, true).slideToggle();
          $(this).parent().toggleClass('active');
        });

        if($.cookie('cdv-popup-shown') === undefined && $('#popup-cdv').length > 0){
          $('#popup-cdv').fadeIn();
        }
        $('#popup-cdv .close-popup').on('click',function(){
          $('#popup-cdv').fadeOut();
          $.cookie('cdv-popup-shown',true,{path: '/', expires: 0.1});
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        //menuOffsetTop = $('nav.nav-primary').offset().top;
        //if($(window).width()>768){
        //    menuOffsetTop = Math.max(menuOffsetTop, 440);
        //}
        if ($(window).scrollTop() > menuOffsetTop) {
          if ( !$('nav.nav-primary').hasClass('sticky')) {
            $('nav.nav-primary').addClass('sticky');
          }
        } else {
          $('nav.nav-primary').removeClass('sticky');
        }
      }
    },
    // Home page
    'home': {
      init: function () {
        if (document.getElementById("upload-image-input")) {
          document.getElementById("upload-image-input").onchange = function () {
            document.getElementById("form-update-user-image").submit();
          };
        }
        if ($(window).width() <= 768) {
          $('.container-banner').each(function () {
            if ($('img.mobile-image', this).length > 0) {
              $('img', this).hide();
              $('img.mobile-image', this).show();
            }
          });
          $('nav.nav-primary').insertAfter($('header'));
          menuOffsetTop = $('nav.nav-primary').offset().top;
        }else{
          $('nav.nav-primary').insertBefore($('div.wrap[role="document"]'));
          menuOffsetTop = $('nav.nav-primary').offset().top;
        }
        $(window).resize(function () {
          if ($(window).width() <= 768) {
            $('.container-banner').each(function () {
              if ($('img.mobile-image', this).length > 0) {
                $('img', this).hide();
                $('img.mobile-image', this).show();
              }
            });
            // If the current active element is a text input, we can assume the soft keyboard is visible.
            // added to avoid keyboard automatically close on android
            if ($(document.activeElement).attr('type') !== 'text') {
              $('nav.nav-primary').insertAfter($('header'));
            }
            menuOffsetTop = $('nav.nav-primary').offset().top;
          } else {
            $('.container-banner').each(function () {
              if ($('img.mobile-image', this).length > 0) {
                $('img', this).show();
                $('img.mobile-image', this).hide();
              }
            });
            $('nav.nav-primary').insertBefore($('div.wrap[role="document"]'));
            menuOffsetTop = $('nav.nav-primary').offset().top;
          }
        });
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        $('.preview-images .image-preview').on("click",function(){
          var postId = $(this).data('post-id');
          $('#featured-video-list .featured-video-post.active').hide().removeClass('active');
          $('#featured-video-list .featured-video-post[data-post-id="' + postId + '"]').show().addClass('active');
          $('.preview-images .image-preview.active').removeClass('active');
          $(this).addClass('active');
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    // Template contatti page, note the change from about-us to about_us.
    'contatti': {
      init: function () {
        if ($(window).width() > 768) {
          var trainersH = $('ul.trainers').outerHeight();
          $('ul.trainers li').each(function () {
            $(this).height(trainersH - 50);
          });
        }
        $('ul.trainers li').on('click', function () {
          $('ul.trainers li').removeClass('active');
          $('ul.trainers li .container-arrow').removeClass('active');
          $(this).addClass('active');
          $('.container-arrow', this).addClass('active');
          if (!$('.container-form-trainer').is(':visible')) {
            $('.container-form-trainer').show();
          }
          if ($(window).width() <= 768) {
            $('.container-form-trainer').insertAfter($(this));
          } else {
            $('.container-form-trainer').insertAfter($('ul.trainers'));
          }
        });
        $(window).resize(function () {
          if ($(window).width() <= 768) {
            $('.container-form-trainer').insertAfter($('ul.trainers li.active'));
          } else {
            $('.container-form-trainer').insertAfter($('ul.trainers'));
          }
        });
      }
    },
    'dashboard': {
      init: function () {
        document.getElementById("upload-image-input").onchange = function () {
          document.getElementById("form-update-user-image").submit();
        };
        $('.dash-frasi .from-redazione ul li.phrase').on('click', function () {
          $('#textarea-frase').val($(this).text());
          $('#form-cambia-frase').submit();
        });
        $('.from-redazione .cont-scroll').height(250).jScrollPane({'mouseWheelSpeed': 30});
      }
    },
    'single': {
      init: function () {
        if ($(window).width() <= 768){
          $('aside.sidebar .sidebar-author').insertBefore('main.main .prev-next-posts');
          $('aside.sidebar .post-info').insertBefore('main.main .prev-next-posts');
          if($('aside .tab-ingredienti').length > 0) {
            $('aside .tab-ingredienti').insertAfter($('article .post-attributes'));
          }
        }
        $(window).resize(function () {
          if ($(window).width() <= 768) {
            $('aside.sidebar .sidebar-author').insertBefore('main.main .prev-next-posts');
            $('aside.sidebar .post-info').insertBefore('main.main .prev-next-posts');
            $('aside .tab-ingredienti').insertAfter($('article .post-attributes'));
          } else {
            $('main.main .post-info').insertAfter('aside.sidebar .post-attachment');
            $('main.main .sidebar-author').insertAfter('aside.sidebar .post-attachment');
            $('article .tab-ingredienti').prependTo($('aside'));
          }
        });
      }
    },
    'page_template_template_mealplan':{
      init: function () {
        var mySwiper = new Swiper("#tutorial-slider.swiper-container", {
          'slidesPerView': 'auto',
          'pagination': '.swiper-pagination',
          'nextButton': '.swiper-button-next',
          'prevButton': '.swiper-button-prev'
        });

        $('.minify-text .show-text-minified').on('click',function(){
          $('.minify-text .page-content-text').css('height','auto').slideDown(300);
          $(this).hide();
        });

        $('.reopen-tutorial-bar').on('click',function(){
          $('#tutorial-slider').slideDown(300, function(){
            mySwiper.init();
          });
          $('.minify-text .show-text-minified').trigger('click');
          $('.minify-text .hide-text-minified').show();
          $('.close-tutorial').show();
          $('.reopen-tutorial-bar').hide();
        });
        $('.close-tutorial, .minify-text .hide-text-minified').on('click',function(){
          $('#tutorial-slider').slideUp(300, function(){
            mySwiper.destroy(false,true);
          });
          $('.reopen-tutorial-bar').show();
          $.cookie('cdv-mealplan-tutorial',true,{path: '/', expires: 20});
          $('.close-tutorial').hide();
          $('.minify-text .page-content-text').css('height',0).slideUp(300);
          $('.minify-text .hide-text-minified').hide();
        });

        if($.cookie('cdv-mealplan-tutorial') === undefined && $('#tutorial-slider').length > 0){
          $('#tutorial-slider').slideDown(300, function(){
            mySwiper.init();
          });
          $('.close-tutorial').show();
          $('.reopen-tutorial-bar').hide();
          $('.minify-text .show-text-minified').trigger('click');
          $('.minify-text .hide-text-minified').show();
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Casadivita;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery.noConflict();
